// Imports
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&family=Titillium+Web:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

// Bootstrap
$font-size-base: 16;

$font-family-display: 'Orbitron', serif;
$font-family-sans-serif: 'Titillium Web', sans-serif;
$font-family-serif: 'Courier New', serif;

// Tip: Use goldenratio to generate font base sizes
// https://type-scale.com/
$font-sizes: (
  1: 52px,
  2: 42px,
  3: 36px,
  4: 32px,
  5: 25px,
  6: 22px,
  body-xxs: 12px,
  body-xs: 14px,
  body-sm: 16px,
  body-md: 20px,
  body-lg: 26px,
);

$headings-font-family: $font-family-display;
$headings-font-weight: 800;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 500;

$lead-font-size: map-get($font-sizes, body-lg);
$lead-font-weight: 600;

$text-muted: var(--bs-gray-600);

$headings-margin-bottom: 0.5em;
$link-color: theme-color(primary);
$link-hover-decoration: underline;

// Other
//? Put own extra variables here
