.header {
  &__pixel-rust {
    width: 100%;
    height: 200px;
    margin-top: -100px;
    background-image: url('/assets/gfx/pixel-rust-white.svg');
    background-position: bottom;
    background-repeat: repeat-x;
  }
}
