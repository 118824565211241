:root {
  --chip-size: 32px;
  --chip-color: #{var(--bs-blue)};
  --chip-bg-color: #{var(--bs-yellow)};
}

.chip {
  @include size(#{var(--chip-size)});
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--chip-bg-color);
  color: var(--chip-color);
  font-weight: 700;
  font-size: 1.25rem;

  .icon {
    fill: currentColor;
    stroke: currentColor;
  }
}

.chip--md {
  --chip-size: 38px;
}

// ToDo make color loop
.chip--blue {
  --chip-color: #{var(--bs-light)};
  --chip-bg-color: #{var(--bs-blue)};
}
