%svg-common {
  background: url("/assets/dist/svg/scss/svg/sprite.css.svg") no-repeat;
  display: inline-block;
}

.icon-arrow-left {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 0;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-arrow-right {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 4.317789291882556%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-calendar {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 8.635578583765112%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-calendar-add {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 12.953367875647668%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-check {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 17.271157167530223%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-chevron-down {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 21.58894645941278%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-chevron-left {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 25.906735751295336%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-chevron-right {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 30.22452504317789%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-chevron-up {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 34.542314335060446%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-cross {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 38.86010362694301%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-facebook {
  --icon-width: 200;
  --icon-height: 200;
  --relative-pos: 0 49.6031746031746%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-happy {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 60.44905008635578%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-heart {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 64.76683937823834%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-instagram {
  --icon-width: 25;
  --icon-height: 25;
  --relative-pos: 0 67.6246830092984%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-linkedin {
  --icon-width: 15;
  --icon-height: 15;
  --relative-pos: 0 69.1533948030176%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-loader {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 72.53886010362694%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-plus {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 76.85664939550949%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-sad {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 81.17443868739205%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.icon-twitter {
  --icon-width: 200;
  --icon-height: 200;
  --relative-pos: 0 98.21428571428571%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
.pixel-rust {
  --icon-width: 200;
  --icon-height: 17.33;
  --relative-pos: 0 99.9437291608926%;

  @extend %svg-common;
  background-position: var(--relative-pos);
}
