.activity-card {
  border-radius: $border-radius-sm;
  background-color: var(--bs-light-blue);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: spacer(4) spacer(3);

  &__chip {
    position: absolute;
    left: spacer(3);
    top: spacer(3);
  }

  &__title {
    margin-left: calc(var(--chip-size) + 5px);
    margin-right: calc(var(--chip-size) + 5px);
    margin-bottom: 0px;
    text-align: center;
    font-weight: 700;
    color: var(--bs-blue);
    line-height: 120%;
    font-size: rem(20px);
    font-family: $font-family-sans-serif;
  }

  &__figure {
    width: 100%;
    position: relative;
    // padding-bottom: 60%;

    .date-card {
      @include abs(50%, 0, null, null);
      transform: translate(#{spacer(4), -50%});

      .card {
        // min-height: 80%;
        border-radius: $border-radius;
        padding: spacer(4) spacer(3);
      }
    }

    &.has--endDate {
      .date-card {
        position: relative;
        top: 0;
        right: 0;
        height: auto;

        .card {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
        }

        .date__end,
        .date__start {
          width: 40%;
        }

        .date__time {
          width: 100%;
          flex-direction: row;
          position: relative;

          .chip {
            @include abs(null, 0, 0, null);
            transform: translate(0, 50%);
          }
        }
      }
    }

    .fstr-youtube__container,
    img {
      // @include abs(0);
      // height: 100%;
      width: 100%;
      min-height: 200px;
      border-radius: $border-radius;
    }
  }

  &__footer,
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__paragraph {
    text-align: center;
    margin-left: spacer(2);
    margin-right: spacer(2);

    * {
      font-size: rem(16px);
    }
  }
}
