body {
  --topbar-height: 90px;

  @include media-breakpoint-up(md) {
    --topbar-height: 140px;
  }
}

.topbar {
  text-align: center;
  @include abs(0, 0, auto, 0);
  z-index: 99;
}

.pt-topbar {
  padding-top: var(--topbar-height);
}

.logo {
  width: 100%;
  max-height: 80px;
  position: relative;

  @include media-breakpoint-up(md) {
    max-height: 120px;
  }
}
