.quiz {
  overflow: hidden;
  display: block;
}

.quiz-list {
  $number-of-cards-shown: 3;
  --card-height: 60vh;
  --card-distance: 7px;

  position: relative;
  height: calc(var(--card-height) + (#{$number-of-cards-shown} * var(--card-distance)));

  &__item {
    position: absolute;
    display: flex;
    width: calc(100% - (#{$number-of-cards-shown} * var(--card-distance)));
    min-height: var(--card-height);
    z-index: calc(var(--quiz-items-total) - var(--quiz-item-index));
    transition: transform 0.2s;
    transform: translate3d(
      calc(var(--quiz-item-distance) * var(--card-distance)),
      calc(var(--quiz-item-distance) * var(--card-distance)),
      0
    );

    // different shade per card depth
    @for $i from 1 through $number-of-cards-shown {
      &[data-distance='#{$i}'] {
        background-color: darkenColor(var(--bs-primary), ($i * 0.2));
      }
    }

    // animate out of screen
    &.is--done {
      transition: transform 0.5s, opacity 0.2s 0.2s;
      transform: translate3d(calc(-100% - 20px), 0, 0);
      opacity: 0;
      pointer-events: none;
    }

    &-loader {
      min-height: 50vh;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.quiz__question {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card.card--quiz {
  text-align: left;
}
