.roadmap {
  .header__pixel-rust {
    margin-top: -49px;
    background-size: contain;
  }

  &__layout {
    row-gap: 1.5rem;
  }
}
