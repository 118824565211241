:root {
  font-size: $font-size-root;
}

body {
  font-size: $font-size-base;
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
}

%heading {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-style: $headings-font-style;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  word-break: break-word;
  //text-wrap: balance;
  hyphens: auto;
}

h1,
.h1 {
  @extend %heading;
  @include get-font-size(1);
}

h2,
.h2 {
  @extend %heading;
  @include get-font-size(2);
}

h3,
.h3 {
  @extend %heading;
  @include get-font-size(3);
}

h4,
.h4 {
  @extend %heading;
  @include get-font-size(4);
}

h5,
.h5 {
  @extend %heading;
  @include get-font-size(5);
}

h6,
.h6 {
  @extend %heading;
  @include get-font-size(6);
}

.lead {
  font-size: rem($lead-font-size);
  font-weight: $lead-font-weight;

  @include media-breakpoint-down(sm) {
    @include get-font-size(body-md);
  }
}

p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  font-size: 1.2rem;
}

a,
.link {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

$body-sizes: 'xxs', 'xs', 'sm', 'md', 'lg';

@each $size in $body-sizes {
  .type-body-#{$size} {
    @include get-font-size(body-#{$size});
  }
}

strong {
  font-weight: bold;
}
