app-quiz-slider {
  height: 100%;
}
.quiz-slider {
  --label-size: 4.2em;

  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__custom-label {
    --slider-label-background: var(--bs-light);
    --slider-label-color: var(--bs-primary);
    position: absolute;
    transition: left 0.2s;

    background-color: var(--slider-label-background);
    color: var(--slider-label-color);
    width: var(--label-size);
    height: var(--label-size);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-37%);

    &::after {
      --height: calc(var(--label-size) / 3);
      --width: calc(var(--label-size) / 4);
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: var(--width) solid transparent;
      border-right: var(--width) solid transparent;
      border-top: var(--height) solid var(--slider-label-background);
      position: absolute;
      bottom: calc(var(--height) * -0.5);
      transition: rotation 0.2s;

      transform: rotate(calc(var(--slider-speed) * 300deg));
      transform-origin: center top;
    }

    app-emoji-animation {
      display: block;
      margin-top: 6px;
      position: relative;
      z-index: 10;
    }
  }

  &__slider.mat-mdc-slider {
    --mdc-slider-handle-color: var(--bs-secondary);
    --mdc-slider-focus-handle-color: var(--bs-secondary);
    --mdc-slider-hover-handle-color: var(--bs-secondary);
    --mdc-slider-active-track-color: var(--bs-light);
    --mdc-slider-inactive-track-color: var(--bs-light);
    --mdc-slider-with-tick-marks-active-container-color: var(--bs-tertiary);
    --mdc-slider-with-tick-marks-inactive-container-color: var(--bs-light);
    --mat-mdc-slider-ripple-color: var(--bs-light);
    --mat-mdc-slider-hover-ripple-color: #{rgba(theme-color(secondary), 0.05)};
    --mat-mdc-slider-focus-ripple-color: #{rgba(theme-color(secondary), 0.2)};

    width: 100%;
    margin-top: calc(var(--label-size));
  }
}
