@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&family=Titillium+Web:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(0, 45, 204, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(255, 247, 53, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary {
  color: #000 !important;
  background-color: RGBA(53, 255, 134, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(53, 255, 134, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(0, 45, 204, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(253, 126, 20, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(0, 0, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-muted {
  color: #000 !important;
  background-color: RGBA(173, 181, 189, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #002dcc !important;
}
.link-primary:hover, .link-primary:focus {
  color: #0024a3 !important;
}

.link-secondary {
  color: #fff735 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #fff95d !important;
}

.link-tertiary {
  color: #35ff86 !important;
}
.link-tertiary:hover, .link-tertiary:focus {
  color: #5dff9e !important;
}

.link-success {
  color: #35ff86 !important;
}
.link-success:hover, .link-success:focus {
  color: #5dff9e !important;
}

.link-info {
  color: #002dcc !important;
}
.link-info:hover, .link-info:focus {
  color: #0024a3 !important;
}

.link-warning {
  color: #fd7e14 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #fd9843 !important;
}

.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: white !important;
}
.link-light:hover, .link-light:focus {
  color: white !important;
}

.link-white {
  color: white !important;
}
.link-white:hover, .link-white:focus {
  color: white !important;
}

.link-dark {
  color: black !important;
}
.link-dark:hover, .link-dark:focus {
  color: black !important;
}

.link-muted {
  color: #adb5bd !important;
}
.link-muted:hover, .link-muted:focus {
  color: #bdc4ca !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 390px) {
  .sticky-xsm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xsm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-muted {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-muted-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.45rem + 2.4vw) !important;
}

.fs-2 {
  font-size: calc(1.3875rem + 1.65vw) !important;
}

.fs-3 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-4 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-5 {
  font-size: calc(1.28125rem + 0.375vw) !important;
}

.fs-6 {
  font-size: calc(1.2625rem + 0.15vw) !important;
}

.fs-body-xxs {
  font-size: 0.75rem !important;
}

.fs-body-xs {
  font-size: 0.875rem !important;
}

.fs-body-sm {
  font-size: 1rem !important;
}

.fs-body-md {
  font-size: 1.25rem !important;
}

.fs-body-lg {
  font-size: calc(1.2875rem + 0.45vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-gray-600) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 390px) {
  .float-xsm-start {
    float: left !important;
  }
  .float-xsm-end {
    float: right !important;
  }
  .float-xsm-none {
    float: none !important;
  }
  .d-xsm-inline {
    display: inline !important;
  }
  .d-xsm-inline-block {
    display: inline-block !important;
  }
  .d-xsm-block {
    display: block !important;
  }
  .d-xsm-grid {
    display: grid !important;
  }
  .d-xsm-table {
    display: table !important;
  }
  .d-xsm-table-row {
    display: table-row !important;
  }
  .d-xsm-table-cell {
    display: table-cell !important;
  }
  .d-xsm-flex {
    display: flex !important;
  }
  .d-xsm-inline-flex {
    display: inline-flex !important;
  }
  .d-xsm-none {
    display: none !important;
  }
  .flex-xsm-fill {
    flex: 1 1 auto !important;
  }
  .flex-xsm-row {
    flex-direction: row !important;
  }
  .flex-xsm-column {
    flex-direction: column !important;
  }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xsm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xsm-start {
    justify-content: flex-start !important;
  }
  .justify-content-xsm-end {
    justify-content: flex-end !important;
  }
  .justify-content-xsm-center {
    justify-content: center !important;
  }
  .justify-content-xsm-between {
    justify-content: space-between !important;
  }
  .justify-content-xsm-around {
    justify-content: space-around !important;
  }
  .justify-content-xsm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xsm-start {
    align-items: flex-start !important;
  }
  .align-items-xsm-end {
    align-items: flex-end !important;
  }
  .align-items-xsm-center {
    align-items: center !important;
  }
  .align-items-xsm-baseline {
    align-items: baseline !important;
  }
  .align-items-xsm-stretch {
    align-items: stretch !important;
  }
  .align-content-xsm-start {
    align-content: flex-start !important;
  }
  .align-content-xsm-end {
    align-content: flex-end !important;
  }
  .align-content-xsm-center {
    align-content: center !important;
  }
  .align-content-xsm-between {
    align-content: space-between !important;
  }
  .align-content-xsm-around {
    align-content: space-around !important;
  }
  .align-content-xsm-stretch {
    align-content: stretch !important;
  }
  .align-self-xsm-auto {
    align-self: auto !important;
  }
  .align-self-xsm-start {
    align-self: flex-start !important;
  }
  .align-self-xsm-end {
    align-self: flex-end !important;
  }
  .align-self-xsm-center {
    align-self: center !important;
  }
  .align-self-xsm-baseline {
    align-self: baseline !important;
  }
  .align-self-xsm-stretch {
    align-self: stretch !important;
  }
  .order-xsm-first {
    order: -1 !important;
  }
  .order-xsm-0 {
    order: 0 !important;
  }
  .order-xsm-1 {
    order: 1 !important;
  }
  .order-xsm-2 {
    order: 2 !important;
  }
  .order-xsm-3 {
    order: 3 !important;
  }
  .order-xsm-4 {
    order: 4 !important;
  }
  .order-xsm-5 {
    order: 5 !important;
  }
  .order-xsm-last {
    order: 6 !important;
  }
  .m-xsm-0 {
    margin: 0 !important;
  }
  .m-xsm-1 {
    margin: 0.25rem !important;
  }
  .m-xsm-2 {
    margin: 0.5rem !important;
  }
  .m-xsm-3 {
    margin: 1rem !important;
  }
  .m-xsm-4 {
    margin: 1.5rem !important;
  }
  .m-xsm-5 {
    margin: 3rem !important;
  }
  .m-xsm-auto {
    margin: auto !important;
  }
  .mx-xsm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xsm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xsm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xsm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xsm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xsm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xsm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xsm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xsm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xsm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xsm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xsm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xsm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xsm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xsm-0 {
    margin-top: 0 !important;
  }
  .mt-xsm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xsm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xsm-3 {
    margin-top: 1rem !important;
  }
  .mt-xsm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xsm-5 {
    margin-top: 3rem !important;
  }
  .mt-xsm-auto {
    margin-top: auto !important;
  }
  .me-xsm-0 {
    margin-right: 0 !important;
  }
  .me-xsm-1 {
    margin-right: 0.25rem !important;
  }
  .me-xsm-2 {
    margin-right: 0.5rem !important;
  }
  .me-xsm-3 {
    margin-right: 1rem !important;
  }
  .me-xsm-4 {
    margin-right: 1.5rem !important;
  }
  .me-xsm-5 {
    margin-right: 3rem !important;
  }
  .me-xsm-auto {
    margin-right: auto !important;
  }
  .mb-xsm-0 {
    margin-bottom: 0 !important;
  }
  .mb-xsm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xsm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xsm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xsm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xsm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xsm-auto {
    margin-bottom: auto !important;
  }
  .ms-xsm-0 {
    margin-left: 0 !important;
  }
  .ms-xsm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xsm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xsm-3 {
    margin-left: 1rem !important;
  }
  .ms-xsm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xsm-5 {
    margin-left: 3rem !important;
  }
  .ms-xsm-auto {
    margin-left: auto !important;
  }
  .p-xsm-0 {
    padding: 0 !important;
  }
  .p-xsm-1 {
    padding: 0.25rem !important;
  }
  .p-xsm-2 {
    padding: 0.5rem !important;
  }
  .p-xsm-3 {
    padding: 1rem !important;
  }
  .p-xsm-4 {
    padding: 1.5rem !important;
  }
  .p-xsm-5 {
    padding: 3rem !important;
  }
  .px-xsm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xsm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xsm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xsm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xsm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xsm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xsm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xsm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xsm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xsm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xsm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xsm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xsm-0 {
    padding-top: 0 !important;
  }
  .pt-xsm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xsm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xsm-3 {
    padding-top: 1rem !important;
  }
  .pt-xsm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xsm-5 {
    padding-top: 3rem !important;
  }
  .pe-xsm-0 {
    padding-right: 0 !important;
  }
  .pe-xsm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xsm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xsm-3 {
    padding-right: 1rem !important;
  }
  .pe-xsm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xsm-5 {
    padding-right: 3rem !important;
  }
  .pb-xsm-0 {
    padding-bottom: 0 !important;
  }
  .pb-xsm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xsm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xsm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xsm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xsm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xsm-0 {
    padding-left: 0 !important;
  }
  .ps-xsm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xsm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xsm-3 {
    padding-left: 1rem !important;
  }
  .ps-xsm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xsm-5 {
    padding-left: 3rem !important;
  }
  .gap-xsm-0 {
    gap: 0 !important;
  }
  .gap-xsm-1 {
    gap: 0.25rem !important;
  }
  .gap-xsm-2 {
    gap: 0.5rem !important;
  }
  .gap-xsm-3 {
    gap: 1rem !important;
  }
  .gap-xsm-4 {
    gap: 1.5rem !important;
  }
  .gap-xsm-5 {
    gap: 3rem !important;
  }
  .text-xsm-start {
    text-align: left !important;
  }
  .text-xsm-end {
    text-align: right !important;
  }
  .text-xsm-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3.25rem !important;
  }
  .fs-2 {
    font-size: 2.625rem !important;
  }
  .fs-3 {
    font-size: 2.25rem !important;
  }
  .fs-4 {
    font-size: 2rem !important;
  }
  .fs-5 {
    font-size: 1.5625rem !important;
  }
  .fs-6 {
    font-size: 1.375rem !important;
  }
  .fs-body-lg {
    font-size: 1.625rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 0.75rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-xsm, .container {
    max-width: 800px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xsm, .container {
    max-width: 960px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 390px) {
  .col-xsm {
    flex: 1 0 0%;
  }
  .row-cols-xsm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xsm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xsm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xsm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xsm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xsm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xsm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xsm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xsm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xsm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xsm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xsm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xsm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xsm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xsm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xsm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xsm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xsm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xsm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xsm-0 {
    margin-left: 0;
  }
  .offset-xsm-1 {
    margin-left: 8.33333333%;
  }
  .offset-xsm-2 {
    margin-left: 16.66666667%;
  }
  .offset-xsm-3 {
    margin-left: 25%;
  }
  .offset-xsm-4 {
    margin-left: 33.33333333%;
  }
  .offset-xsm-5 {
    margin-left: 41.66666667%;
  }
  .offset-xsm-6 {
    margin-left: 50%;
  }
  .offset-xsm-7 {
    margin-left: 58.33333333%;
  }
  .offset-xsm-8 {
    margin-left: 66.66666667%;
  }
  .offset-xsm-9 {
    margin-left: 75%;
  }
  .offset-xsm-10 {
    margin-left: 83.33333333%;
  }
  .offset-xsm-11 {
    margin-left: 91.66666667%;
  }
  .g-xsm-0,
  .gx-xsm-0 {
    --bs-gutter-x: 0;
  }
  .g-xsm-0,
  .gy-xsm-0 {
    --bs-gutter-y: 0;
  }
  .g-xsm-1,
  .gx-xsm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xsm-1,
  .gy-xsm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xsm-2,
  .gx-xsm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xsm-2,
  .gy-xsm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xsm-3,
  .gx-xsm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xsm-3,
  .gy-xsm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xsm-4,
  .gx-xsm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xsm-4,
  .gy-xsm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xsm-5,
  .gx-xsm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xsm-5,
  .gy-xsm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
:root {
  --bs-blue: #002dcc;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #fff735;
  --bs-green: #35ff86;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-light-blue: #f4f6ff;
  --bs-gray-900: #212529;
  --bs-gray-800: #343a40;
  --bs-gray-700: #495057;
  --bs-gray-600: #6c757d;
  --bs-gray-500: #adb5bd;
  --bs-gray-400: #ced4da;
  --bs-gray-300: #dee2e6;
  --bs-gray-200: #e9ecef;
  --bs-gray-100: #f8f9fa;
  --bs-primary: #002dcc;
  --bs-secondary: #fff735;
  --bs-tertiary: #35ff86;
  --bs-success: #35ff86;
  --bs-info: #002dcc;
  --bs-warning: #fd7e14;
  --bs-danger: #dc3545;
  --bs-light: white;
  --bs-white: white;
  --bs-dark: black;
  --bs-muted: #adb5bd;
  --bs-primary-rgb: 0, 45, 204;
  --bs-secondary-rgb: 255, 247, 53;
  --bs-tertiary-rgb: 53, 255, 134;
  --bs-success-rgb: 53, 255, 134;
  --bs-info-rgb: 0, 45, 204;
  --bs-warning-rgb: 253, 126, 20;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 255, 255, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-dark-rgb: 0, 0, 0;
  --bs-muted-rgb: 173, 181, 189;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Titillium Web", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Titillium Web, sans-serif;
  --bs-body-font-size: 16;
  --bs-body-font-weight: 500;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: var(--bs-gray-300);
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.625rem;
  --bs-border-radius-sm: 1.25rem;
  --bs-border-radius-lg: 1.875rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #002dcc;
  --bs-link-hover-color: #0024a3;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus.mdc-list-item, .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #002dcc);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #002dcc);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc3545);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #002dcc;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #002dcc;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #002dcc;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #002dcc;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #002dcc;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #002dcc;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #dc3545;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #dc3545;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card {
  --mat-card-title-text-font: Titillium Web, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Titillium Web, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #002dcc;
  --mdc-linear-progress-track-color: rgba(0, 45, 204, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(0, 45, 204, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(0, 45, 204, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(0, 45, 204, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(0, 45, 204, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(0, 45, 204, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #002dcc;
  --mdc-linear-progress-track-color: rgba(0, 45, 204, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(0, 45, 204, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(0, 45, 204, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(0, 45, 204, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(0, 45, 204, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(0, 45, 204, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc3545;
  --mdc-linear-progress-track-color: rgba(220, 53, 69, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(220, 53, 69, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(220, 53, 69, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 53, 69, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 53, 69, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(220, 53, 69, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Titillium Web, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #002dcc);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #002dcc);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #002dcc);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 45, 204, 0.87);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc3545);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(0, 45, 204, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(0, 45, 204, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 53, 69, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #002dcc);
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #002dcc);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 45, 204, 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #002dcc);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 53, 69, 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc3545);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(16px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 16px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #002dcc);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #002dcc);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(0, 45, 204, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(0, 45, 204, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 53, 69, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 53, 69, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #002dcc);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #002dcc);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Titillium Web, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Titillium Web, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #002dcc;
  --mdc-chip-elevated-disabled-container-color: #002dcc;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #002dcc;
  --mdc-chip-elevated-disabled-container-color: #002dcc;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc3545;
  --mdc-chip-elevated-disabled-container-color: #dc3545;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  height: 32px;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #0028c7;
  --mdc-switch-selected-handle-color: #0028c7;
  --mdc-switch-selected-hover-state-layer-color: #0028c7;
  --mdc-switch-selected-pressed-state-layer-color: #0028c7;
  --mdc-switch-selected-focus-handle-color: #0011ad;
  --mdc-switch-selected-hover-handle-color: #0011ad;
  --mdc-switch-selected-pressed-handle-color: #0011ad;
  --mdc-switch-selected-focus-track-color: #4d6cdb;
  --mdc-switch-selected-hover-track-color: #4d6cdb;
  --mdc-switch-selected-pressed-track-color: #4d6cdb;
  --mdc-switch-selected-track-color: #4d6cdb;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #0028c7;
  --mdc-switch-selected-handle-color: #0028c7;
  --mdc-switch-selected-hover-state-layer-color: #0028c7;
  --mdc-switch-selected-pressed-state-layer-color: #0028c7;
  --mdc-switch-selected-focus-handle-color: #0011ad;
  --mdc-switch-selected-hover-handle-color: #0011ad;
  --mdc-switch-selected-pressed-handle-color: #0011ad;
  --mdc-switch-selected-focus-track-color: #4d6cdb;
  --mdc-switch-selected-hover-track-color: #4d6cdb;
  --mdc-switch-selected-pressed-track-color: #4d6cdb;
  --mdc-switch-selected-track-color: #4d6cdb;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #d8303f;
  --mdc-switch-selected-handle-color: #d8303f;
  --mdc-switch-selected-hover-state-layer-color: #d8303f;
  --mdc-switch-selected-pressed-state-layer-color: #d8303f;
  --mdc-switch-selected-focus-handle-color: #c61620;
  --mdc-switch-selected-hover-handle-color: #c61620;
  --mdc-switch-selected-pressed-handle-color: #c61620;
  --mdc-switch-selected-focus-track-color: #e7727d;
  --mdc-switch-selected-hover-track-color: #e7727d;
  --mdc-switch-selected-pressed-track-color: #e7727d;
  --mdc-switch-selected-track-color: #e7727d;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #002dcc;
  --mdc-radio-selected-hover-icon-color: #002dcc;
  --mdc-radio-selected-icon-color: #002dcc;
  --mdc-radio-selected-pressed-icon-color: #002dcc;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #002dcc;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #002dcc;
  --mdc-radio-selected-hover-icon-color: #002dcc;
  --mdc-radio-selected-icon-color: #002dcc;
  --mdc-radio-selected-pressed-icon-color: #002dcc;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #002dcc;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc3545;
  --mdc-radio-selected-hover-icon-color: #dc3545;
  --mdc-radio-selected-icon-color: #dc3545;
  --mdc-radio-selected-pressed-icon-color: #dc3545;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #dc3545;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #002dcc;
  --mdc-slider-focus-handle-color: #002dcc;
  --mdc-slider-hover-handle-color: #002dcc;
  --mdc-slider-active-track-color: #002dcc;
  --mdc-slider-inactive-track-color: #002dcc;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #002dcc;
  --mat-mdc-slider-ripple-color: #002dcc;
  --mat-mdc-slider-hover-ripple-color: rgba(0, 45, 204, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 45, 204, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #002dcc;
  --mdc-slider-focus-handle-color: #002dcc;
  --mdc-slider-hover-handle-color: #002dcc;
  --mdc-slider-active-track-color: #002dcc;
  --mdc-slider-inactive-track-color: #002dcc;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #002dcc;
  --mat-mdc-slider-ripple-color: #002dcc;
  --mat-mdc-slider-hover-ripple-color: rgba(0, 45, 204, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 45, 204, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc3545;
  --mdc-slider-focus-handle-color: #dc3545;
  --mdc-slider-hover-handle-color: #dc3545;
  --mdc-slider-active-track-color: #dc3545;
  --mdc-slider-inactive-track-color: #dc3545;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc3545;
  --mat-mdc-slider-ripple-color: #dc3545;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 53, 69, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 53, 69, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Titillium Web, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #002dcc);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #002dcc);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #002dcc;
  --mdc-radio-selected-hover-icon-color: #002dcc;
  --mdc-radio-selected-icon-color: #002dcc;
  --mdc-radio-selected-pressed-icon-color: #002dcc;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #002dcc;
  --mdc-radio-selected-hover-icon-color: #002dcc;
  --mdc-radio-selected-icon-color: #002dcc;
  --mdc-radio-selected-pressed-icon-color: #002dcc;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc3545;
  --mdc-radio-selected-hover-icon-color: #dc3545;
  --mdc-radio-selected-icon-color: #dc3545;
  --mdc-radio-selected-pressed-icon-color: #dc3545;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #002dcc;
  --mdc-checkbox-selected-hover-icon-color: #002dcc;
  --mdc-checkbox-selected-icon-color: #002dcc;
  --mdc-checkbox-selected-pressed-icon-color: #002dcc;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #002dcc;
  --mdc-checkbox-selected-hover-state-layer-color: #002dcc;
  --mdc-checkbox-selected-pressed-state-layer-color: #002dcc;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #002dcc;
  --mdc-checkbox-selected-hover-icon-color: #002dcc;
  --mdc-checkbox-selected-icon-color: #002dcc;
  --mdc-checkbox-selected-pressed-icon-color: #002dcc;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #002dcc;
  --mdc-checkbox-selected-hover-state-layer-color: #002dcc;
  --mdc-checkbox-selected-pressed-state-layer-color: #002dcc;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc3545;
  --mdc-checkbox-selected-hover-icon-color: #dc3545;
  --mdc-checkbox-selected-icon-color: #dc3545;
  --mdc-checkbox-selected-pressed-icon-color: #dc3545;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc3545;
  --mdc-checkbox-selected-hover-state-layer-color: #dc3545;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc3545;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #002dcc;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #002dcc;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Titillium Web, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Titillium Web, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Titillium Web, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 0.009375em;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #002dcc;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #002dcc;
  --mat-tab-header-active-ripple-color: #002dcc;
  --mat-tab-header-inactive-ripple-color: #002dcc;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #002dcc;
  --mat-tab-header-active-hover-label-text-color: #002dcc;
  --mat-tab-header-active-focus-indicator-color: #002dcc;
  --mat-tab-header-active-hover-indicator-color: #002dcc;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #002dcc;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #002dcc;
  --mat-tab-header-active-ripple-color: #002dcc;
  --mat-tab-header-inactive-ripple-color: #002dcc;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #002dcc;
  --mat-tab-header-active-hover-label-text-color: #002dcc;
  --mat-tab-header-active-focus-indicator-color: #002dcc;
  --mat-tab-header-active-hover-indicator-color: #002dcc;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc3545;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #dc3545;
  --mat-tab-header-active-ripple-color: #dc3545;
  --mat-tab-header-inactive-ripple-color: #dc3545;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc3545;
  --mat-tab-header-active-hover-label-text-color: #dc3545;
  --mat-tab-header-active-focus-indicator-color: #dc3545;
  --mat-tab-header-active-hover-indicator-color: #dc3545;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #002dcc;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #002dcc;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc3545;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Titillium Web, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-letter-spacing: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #002dcc;
  --mdc-checkbox-selected-hover-icon-color: #002dcc;
  --mdc-checkbox-selected-icon-color: #002dcc;
  --mdc-checkbox-selected-pressed-icon-color: #002dcc;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #002dcc;
  --mdc-checkbox-selected-hover-state-layer-color: #002dcc;
  --mdc-checkbox-selected-pressed-state-layer-color: #002dcc;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #002dcc;
  --mdc-checkbox-selected-hover-icon-color: #002dcc;
  --mdc-checkbox-selected-icon-color: #002dcc;
  --mdc-checkbox-selected-pressed-icon-color: #002dcc;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #002dcc;
  --mdc-checkbox-selected-hover-state-layer-color: #002dcc;
  --mdc-checkbox-selected-pressed-state-layer-color: #002dcc;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc3545;
  --mdc-checkbox-selected-hover-icon-color: #dc3545;
  --mdc-checkbox-selected-icon-color: #dc3545;
  --mdc-checkbox-selected-pressed-icon-color: #dc3545;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc3545;
  --mdc-checkbox-selected-hover-state-layer-color: #dc3545;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc3545;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #002dcc;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #002dcc;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc3545;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #002dcc;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #002dcc;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc3545;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #002dcc;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #002dcc;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc3545;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #002dcc;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #002dcc;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc3545;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #002dcc;
  --mat-mdc-button-ripple-color: rgba(0, 45, 204, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #002dcc;
  --mat-mdc-button-ripple-color: rgba(0, 45, 204, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc3545;
  --mat-mdc-button-ripple-color: rgba(220, 53, 69, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #002dcc;
  --mat-mdc-button-persistent-ripple-color: #002dcc;
  --mat-mdc-button-ripple-color: rgba(0, 45, 204, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #002dcc;
  --mat-mdc-button-persistent-ripple-color: #002dcc;
  --mat-mdc-button-ripple-color: rgba(0, 45, 204, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc3545;
  --mat-mdc-button-persistent-ripple-color: #dc3545;
  --mat-mdc-button-ripple-color: rgba(220, 53, 69, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #002dcc;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #002dcc;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc3545;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #002dcc;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Titillium Web, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(0, 45, 204, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, "Titillium Web", sans-serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #002dcc;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #002dcc;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc3545;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #002dcc;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #002dcc;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc3545;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "Titillium Web", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 0.0178571429em;
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: "Titillium Web", sans-serif;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 45, 204, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 45, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 45, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #002dcc;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 45, 204, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 45, 204, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 45, 204, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 45, 204, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 45, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 45, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #002dcc;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 45, 204, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 45, 204, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 45, 204, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 53, 69, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 53, 69, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 53, 69, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc3545;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 53, 69, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 53, 69, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 53, 69, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #002dcc;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #002dcc;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #dc3545;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-calendar {
  font-family: "Titillium Web", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 0.0178571429em;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-icon.mat-primary {
  color: #002dcc;
}
.mat-icon.mat-accent {
  color: #002dcc;
}
.mat-icon.mat-warn {
  color: #dc3545;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #002dcc;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #002dcc;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc3545;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc3545;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc3545;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "Titillium Web", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 16px;
}

.mat-step-label-selected {
  font-size: 16px;
  font-weight: 400;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #002dcc;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #002dcc;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #dc3545;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 0.0125em;
  margin: 0;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: "Titillium Web", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.app-container .mat-mdc-checkbox {
  --mdc-checkbox-unselected-icon-color: var(--bs-light-border-subtle);
  --mdc-checkbox-unselected-hover-icon-color: var(--bs-tertiary-color);
  --mdc-checkbox-state-layer-size: 32px;
}
.app-container .mat-mdc-checkbox .mdc-form-field {
  align-items: start;
  color: var(--bs-body-color);
}
.app-container .mat-mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  background: var(--bs-tertiary-bg);
}
.app-container .mat-mdc-checkbox .mdc-checkbox__background {
  border-radius: 5px;
}
.app-container .mat-mdc-checkbox .mdc-checkbox__checkmark {
  padding: 2px;
}
.app-container .mat-mdc-checkbox label {
  margin-top: 0.35em;
}
.app-container .mat-mdc-select-value,
.app-container .mat-mdc-select-arrow {
  color: var(--bs-primary-800);
}
.app-container .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--bs-primary-bg-subtle);
}
.app-container .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--bs-primary-600);
}
.app-container .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--bs-primary-800);
}
.app-container .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-color: var(--bs-primary);
}
.app-container .mat-mdc-form-field {
  width: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

li {
  list-style-type: none;
}

blockquote,
q {
  quotes: none;
}
blockquote::before, blockquote::after,
q::before,
q::after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  margin: 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 16;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
}

h6,
.h6, h5,
.h5,
.app .fstr-cookie-notice .fstr-cookie-notice__title, h4,
.h4, h3,
.h3, h2,
.h2, h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-family: "Orbitron", serif;
  font-weight: 800;
  line-height: 1.2;
  word-break: break-word;
  hyphens: auto;
}

h1,
.h1 {
  font-size: calc(1.45rem + 2.4vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 3.25rem;
  }
}

h2,
.h2 {
  font-size: calc(1.3875rem + 1.65vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2.625rem;
  }
}

h3,
.h3 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 2.25rem;
  }
}

h4,
.h4 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 2rem;
  }
}

h5,
.h5,
.app .fstr-cookie-notice .fstr-cookie-notice__title {
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  h5,
  .h5,
  .app .fstr-cookie-notice .fstr-cookie-notice__title {
    font-size: 1.5625rem;
  }
}

h6,
.h6 {
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  h6,
  .h6 {
    font-size: 1.375rem;
  }
}

.lead {
  font-size: 1.625rem;
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .lead {
    font-size: 1.25rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

a,
.link {
  color: #002dcc;
  text-decoration: underline;
}
a:hover,
.link:hover {
  color: #0024a3;
  text-decoration: underline;
}

.type-body-xxs {
  font-size: 0.75rem;
}

.type-body-xs {
  font-size: 0.875rem;
}

.type-body-sm {
  font-size: 1rem;
}

.type-body-md {
  font-size: 1.25rem;
}

.type-body-lg {
  font-size: calc(1.2875rem + 0.45vw);
}
@media (min-width: 1200px) {
  .type-body-lg {
    font-size: 1.625rem;
  }
}

strong {
  font-weight: bold;
}

body {
  --bs-primary: hsl(226.7647058824deg, 100%, 40%);
  --bs-primary-h: 226.7647058824deg;
  --bs-primary-s: 100%;
  --bs-primary-l: 40%;
  --bs-secondary: hsl(57.6237623762deg, 100%, 60.3921568627%);
  --bs-secondary-h: 57.6237623762deg;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 60.3921568627%;
  --bs-tertiary: hsl(144.0594059406deg, 100%, 60.3921568627%);
  --bs-tertiary-h: 144.0594059406deg;
  --bs-tertiary-s: 100%;
  --bs-tertiary-l: 60.3921568627%;
  --bs-success: hsl(144.0594059406deg, 100%, 60.3921568627%);
  --bs-success-h: 144.0594059406deg;
  --bs-success-s: 100%;
  --bs-success-l: 60.3921568627%;
  --bs-info: hsl(226.7647058824deg, 100%, 40%);
  --bs-info-h: 226.7647058824deg;
  --bs-info-s: 100%;
  --bs-info-l: 40%;
  --bs-warning: hsl(27.2961373391deg, 98.3122362869%, 53.5294117647%);
  --bs-warning-h: 27.2961373391deg;
  --bs-warning-s: 98.3122362869%;
  --bs-warning-l: 53.5294117647%;
  --bs-danger: hsl(354.251497006deg, 70.4641350211%, 53.5294117647%);
  --bs-danger-h: 354.251497006deg;
  --bs-danger-s: 70.4641350211%;
  --bs-danger-l: 53.5294117647%;
  --bs-light: hsl(0deg, 0%, 100%);
  --bs-light-h: 0deg;
  --bs-light-s: 0%;
  --bs-light-l: 100%;
  --bs-white: hsl(0deg, 0%, 100%);
  --bs-white-h: 0deg;
  --bs-white-s: 0%;
  --bs-white-l: 100%;
  --bs-dark: hsl(0deg, 0%, 0%);
  --bs-dark-h: 0deg;
  --bs-dark-s: 0%;
  --bs-dark-l: 0%;
  --bs-muted: hsl(210deg, 10.8108108108%, 70.9803921569%);
  --bs-muted-h: 210deg;
  --bs-muted-s: 10.8108108108%;
  --bs-muted-l: 70.9803921569%;
  --bs-blue: hsl(226.7647058824deg, 100%, 40%);
  --bs-blue-h: 226.7647058824deg;
  --bs-blue-s: 100%;
  --bs-blue-l: 40%;
  --bs-indigo: hsl(262.8318584071deg, 89.6825396825%, 50.5882352941%);
  --bs-indigo-h: 262.8318584071deg;
  --bs-indigo-s: 89.6825396825%;
  --bs-indigo-l: 50.5882352941%;
  --bs-purple: hsl(261.2598425197deg, 50.5976095618%, 50.7843137255%);
  --bs-purple-h: 261.2598425197deg;
  --bs-purple-s: 50.5976095618%;
  --bs-purple-l: 50.7843137255%;
  --bs-pink: hsl(332.4705882353deg, 78.7037037037%, 57.6470588235%);
  --bs-pink-h: 332.4705882353deg;
  --bs-pink-s: 78.7037037037%;
  --bs-pink-l: 57.6470588235%;
  --bs-red: hsl(354.251497006deg, 70.4641350211%, 53.5294117647%);
  --bs-red-h: 354.251497006deg;
  --bs-red-s: 70.4641350211%;
  --bs-red-l: 53.5294117647%;
  --bs-orange: hsl(27.2961373391deg, 98.3122362869%, 53.5294117647%);
  --bs-orange-h: 27.2961373391deg;
  --bs-orange-s: 98.3122362869%;
  --bs-orange-l: 53.5294117647%;
  --bs-yellow: hsl(57.6237623762deg, 100%, 60.3921568627%);
  --bs-yellow-h: 57.6237623762deg;
  --bs-yellow-s: 100%;
  --bs-yellow-l: 60.3921568627%;
  --bs-green: hsl(144.0594059406deg, 100%, 60.3921568627%);
  --bs-green-h: 144.0594059406deg;
  --bs-green-s: 100%;
  --bs-green-l: 60.3921568627%;
  --bs-teal: hsl(162.2485207101deg, 72.5321888412%, 45.6862745098%);
  --bs-teal-h: 162.2485207101deg;
  --bs-teal-s: 72.5321888412%;
  --bs-teal-l: 45.6862745098%;
  --bs-cyan: hsl(188.198757764deg, 77.7777777778%, 40.5882352941%);
  --bs-cyan-h: 188.198757764deg;
  --bs-cyan-s: 77.7777777778%;
  --bs-cyan-l: 40.5882352941%;
  --bs-light-blue: hsl(229.0909090909deg, 100%, 97.8431372549%);
  --bs-light-blue-h: 229.0909090909deg;
  --bs-light-blue-s: 100%;
  --bs-light-blue-l: 97.8431372549%;
  --bs-gray-900: hsl(210deg, 10.8108108108%, 14.5098039216%);
  --bs-gray-900-h: 210deg;
  --bs-gray-900-s: 10.8108108108%;
  --bs-gray-900-l: 14.5098039216%;
  --bs-gray-800: hsl(210deg, 10.3448275862%, 22.7450980392%);
  --bs-gray-800-h: 210deg;
  --bs-gray-800-s: 10.3448275862%;
  --bs-gray-800-l: 22.7450980392%;
  --bs-gray-700: hsl(210deg, 8.75%, 31.3725490196%);
  --bs-gray-700-h: 210deg;
  --bs-gray-700-s: 8.75%;
  --bs-gray-700-l: 31.3725490196%;
  --bs-gray-600: hsl(208.2352941176deg, 7.2961373391%, 45.6862745098%);
  --bs-gray-600-h: 208.2352941176deg;
  --bs-gray-600-s: 7.2961373391%;
  --bs-gray-600-l: 45.6862745098%;
  --bs-gray-500: hsl(210deg, 10.8108108108%, 70.9803921569%);
  --bs-gray-500-h: 210deg;
  --bs-gray-500-s: 10.8108108108%;
  --bs-gray-500-l: 70.9803921569%;
  --bs-gray-400: hsl(210deg, 13.9534883721%, 83.137254902%);
  --bs-gray-400-h: 210deg;
  --bs-gray-400-s: 13.9534883721%;
  --bs-gray-400-l: 83.137254902%;
  --bs-gray-300: hsl(210deg, 13.7931034483%, 88.6274509804%);
  --bs-gray-300-h: 210deg;
  --bs-gray-300-s: 13.7931034483%;
  --bs-gray-300-l: 88.6274509804%;
  --bs-gray-200: hsl(210deg, 15.7894736842%, 92.5490196078%);
  --bs-gray-200-h: 210deg;
  --bs-gray-200-s: 15.7894736842%;
  --bs-gray-200-l: 92.5490196078%;
  --bs-gray-100: hsl(210deg, 16.6666666667%, 97.6470588235%);
  --bs-gray-100-h: 210deg;
  --bs-gray-100-s: 16.6666666667%;
  --bs-gray-100-l: 97.6470588235%;
  --bs-font-sans-serif: "Titillium Web", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-gutter-x: 1.5rem;
}

img {
  width: 100%;
  height: auto;
}

.theme-contrast {
  --bs-primary: hsl(226.7647058824deg, 100%, 20%);
  --bs-primary-h: 226.7647058824deg;
  --bs-primary-s: 100%;
  --bs-primary-l: 20%;
  --bs-secondary: hsl(57.6237623762deg, 100%, 40.3921568627%);
  --bs-secondary-h: 57.6237623762deg;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 40.3921568627%;
  --bs-tertiary: hsl(144.0594059406deg, 100%, 40.3921568627%);
  --bs-tertiary-h: 144.0594059406deg;
  --bs-tertiary-s: 100%;
  --bs-tertiary-l: 40.3921568627%;
  --bs-success: hsl(144.0594059406deg, 100%, 40.3921568627%);
  --bs-success-h: 144.0594059406deg;
  --bs-success-s: 100%;
  --bs-success-l: 40.3921568627%;
  --bs-info: hsl(226.7647058824deg, 100%, 20%);
  --bs-info-h: 226.7647058824deg;
  --bs-info-s: 100%;
  --bs-info-l: 20%;
  --bs-warning: hsl(27.2961373391deg, 98.3122362869%, 33.5294117647%);
  --bs-warning-h: 27.2961373391deg;
  --bs-warning-s: 98.3122362869%;
  --bs-warning-l: 33.5294117647%;
  --bs-danger: hsl(354.251497006deg, 70.4641350211%, 33.5294117647%);
  --bs-danger-h: 354.251497006deg;
  --bs-danger-s: 70.4641350211%;
  --bs-danger-l: 33.5294117647%;
  --bs-white: hsl(0deg, 0%, 80%);
  --bs-white-h: 0deg;
  --bs-white-s: 0%;
  --bs-white-l: 80%;
  --bs-dark: hsl(0deg, 0%, -20%);
  --bs-dark-h: 0deg;
  --bs-dark-s: 0%;
  --bs-dark-l: -20%;
  --bs-muted: hsl(210deg, 10.8108108108%, 50.9803921569%);
  --bs-muted-h: 210deg;
  --bs-muted-s: 10.8108108108%;
  --bs-muted-l: 50.9803921569%;
  --bs-blue: hsl(226.7647058824deg, 100%, 20%);
  --bs-blue-h: 226.7647058824deg;
  --bs-blue-s: 100%;
  --bs-blue-l: 20%;
  --bs-indigo: hsl(262.8318584071deg, 89.6825396825%, 30.5882352941%);
  --bs-indigo-h: 262.8318584071deg;
  --bs-indigo-s: 89.6825396825%;
  --bs-indigo-l: 30.5882352941%;
  --bs-purple: hsl(261.2598425197deg, 50.5976095618%, 30.7843137255%);
  --bs-purple-h: 261.2598425197deg;
  --bs-purple-s: 50.5976095618%;
  --bs-purple-l: 30.7843137255%;
  --bs-pink: hsl(332.4705882353deg, 78.7037037037%, 37.6470588235%);
  --bs-pink-h: 332.4705882353deg;
  --bs-pink-s: 78.7037037037%;
  --bs-pink-l: 37.6470588235%;
  --bs-red: hsl(354.251497006deg, 70.4641350211%, 33.5294117647%);
  --bs-red-h: 354.251497006deg;
  --bs-red-s: 70.4641350211%;
  --bs-red-l: 33.5294117647%;
  --bs-orange: hsl(27.2961373391deg, 98.3122362869%, 33.5294117647%);
  --bs-orange-h: 27.2961373391deg;
  --bs-orange-s: 98.3122362869%;
  --bs-orange-l: 33.5294117647%;
  --bs-yellow: hsl(57.6237623762deg, 100%, 40.3921568627%);
  --bs-yellow-h: 57.6237623762deg;
  --bs-yellow-s: 100%;
  --bs-yellow-l: 40.3921568627%;
  --bs-green: hsl(144.0594059406deg, 100%, 40.3921568627%);
  --bs-green-h: 144.0594059406deg;
  --bs-green-s: 100%;
  --bs-green-l: 40.3921568627%;
  --bs-teal: hsl(162.2485207101deg, 72.5321888412%, 25.6862745098%);
  --bs-teal-h: 162.2485207101deg;
  --bs-teal-s: 72.5321888412%;
  --bs-teal-l: 25.6862745098%;
  --bs-cyan: hsl(188.198757764deg, 77.7777777778%, 20.5882352941%);
  --bs-cyan-h: 188.198757764deg;
  --bs-cyan-s: 77.7777777778%;
  --bs-cyan-l: 20.5882352941%;
  --bs-light-blue: hsl(229.0909090909deg, 100%, 77.8431372549%);
  --bs-light-blue-h: 229.0909090909deg;
  --bs-light-blue-s: 100%;
  --bs-light-blue-l: 77.8431372549%;
}

.palette-light {
  --bs-primary: hsl(226.7647058824deg, 100%, 40%);
  --bs-primary-h: 226.7647058824deg;
  --bs-primary-s: 100%;
  --bs-primary-l: 40%;
  --bs-secondary: hsl(57.6237623762deg, 100%, 60.3921568627%);
  --bs-secondary-h: 57.6237623762deg;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 60.3921568627%;
  --bs-primary-overlay: hsl(57.6237623762deg, 100%, 60.3921568627%);
  --bs-primary-overlay-h: 57.6237623762deg;
  --bs-primary-overlay-s: 100%;
  --bs-primary-overlay-l: 60.3921568627%;
  --bs-secondary-overlay: hsl(210deg, 16.6666666667%, 97.6470588235%);
  --bs-secondary-overlay-h: 210deg;
  --bs-secondary-overlay-s: 16.6666666667%;
  --bs-secondary-overlay-l: 97.6470588235%;
  --bs-tertiary: hsl(57.6237623762deg, 100%, 60.3921568627%);
  --bs-tertiary-h: 57.6237623762deg;
  --bs-tertiary-s: 100%;
  --bs-tertiary-l: 60.3921568627%;
}

.palette-dark {
  --bs-primary: hsl(57.6237623762deg, 100%, 60.3921568627%);
  --bs-primary-h: 57.6237623762deg;
  --bs-primary-s: 100%;
  --bs-primary-l: 60.3921568627%;
  --bs-secondary: hsl(226.7647058824deg, 100%, 40%);
  --bs-secondary-h: 226.7647058824deg;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 40%;
  --bs-primary-overlay: hsl(0deg, 0%, 100%);
  --bs-primary-overlay-h: 0deg;
  --bs-primary-overlay-s: 0%;
  --bs-primary-overlay-l: 100%;
  --bs-secondary-overlay: hsl(57.6237623762deg, 100%, 60.3921568627%);
  --bs-secondary-overlay-h: 57.6237623762deg;
  --bs-secondary-overlay-s: 100%;
  --bs-secondary-overlay-l: 60.3921568627%;
  --bs-tertiary: hsl(162.2485207101deg, 72.5321888412%, 45.6862745098%);
  --bs-tertiary-h: 162.2485207101deg;
  --bs-tertiary-s: 72.5321888412%;
  --bs-tertiary-l: 45.6862745098%;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

.bg-secondary {
  background-color: var(--bs-secondary) !important;
}

.bg-tertiary {
  background-color: var(--bs-tertiary) !important;
}

.bg-success {
  background-color: var(--bs-success) !important;
}

.bg-info {
  background-color: var(--bs-info) !important;
}

.bg-warning {
  background-color: var(--bs-warning) !important;
}

.bg-danger {
  background-color: var(--bs-danger) !important;
}

.bg-light {
  background-color: var(--bs-light) !important;
}

.bg-white {
  background-color: var(--bs-white) !important;
}

.bg-dark {
  background-color: var(--bs-dark) !important;
}

.bg-muted {
  background-color: var(--bs-muted) !important;
}

.bg-color-blue {
  background-color: var(--bs-blue) !important;
}

.bg-color-indigo {
  background-color: var(--bs-indigo) !important;
}

.bg-color-purple {
  background-color: var(--bs-purple) !important;
}

.bg-color-pink {
  background-color: var(--bs-pink) !important;
}

.bg-color-red {
  background-color: var(--bs-red) !important;
}

.bg-color-orange {
  background-color: var(--bs-orange) !important;
}

.bg-color-yellow {
  background-color: var(--bs-yellow) !important;
}

.bg-color-green {
  background-color: var(--bs-green) !important;
}

.bg-color-teal {
  background-color: var(--bs-teal) !important;
}

.bg-color-cyan {
  background-color: var(--bs-cyan) !important;
}

.bg-color-light-blue {
  background-color: var(--bs-light-blue) !important;
}

.bg-gray-900 {
  background-color: var(--bs-gray-900) !important;
}

.bg-gray-800 {
  background-color: var(--bs-gray-800) !important;
}

.bg-gray-700 {
  background-color: var(--bs-gray-700) !important;
}

.bg-gray-600 {
  background-color: var(--bs-gray-600) !important;
}

.bg-gray-500 {
  background-color: var(--bs-gray-500) !important;
}

.bg-gray-400 {
  background-color: var(--bs-gray-400) !important;
}

.bg-gray-300 {
  background-color: var(--bs-gray-300) !important;
}

.bg-gray-200 {
  background-color: var(--bs-gray-200) !important;
}

.bg-gray-100 {
  background-color: var(--bs-gray-100) !important;
}

/**
 * Section class for inner padding, outer margin or negative margin.
 * The negative option is used to pull a section over another to create overlapping content.
 */
[class^=section-] {
  position: relative;
}

[class^=section-negative-] {
  z-index: 1;
}

.section-inset-y {
  padding-top: 10vw;
  padding-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-y {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.section-inset-t {
  padding-top: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-t {
    padding-top: 6.25rem;
  }
}

.section-inset-b {
  padding-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-b {
    padding-bottom: 6.25rem;
  }
}

.section-outset-y {
  margin-top: 10vw;
  margin-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-y {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
}

.section-outset-t {
  margin-top: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-t {
    margin-top: 6.25rem;
  }
}

.section-outset-b {
  margin-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-b {
    margin-bottom: 6.25rem;
  }
}

.section-negative-y {
  margin-top: -10vw;
  margin-bottom: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-y {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem;
  }
}

.section-negative-t {
  margin-top: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-t {
    margin-top: -6.25rem;
  }
}

.section-negative-b {
  margin-bottom: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-b {
    margin-bottom: -6.25rem;
  }
}

.text-max-width {
  max-width: 720px;
}
.text-max-width-xs {
  max-width: 550px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-decoration-none:hover, .text-decoration-none:focus {
  text-decoration: none;
}

.font-body {
  font-family: "Titillium Web", sans-serif;
}

@media screen and (min-width: 800px) {
  .container-size-xs {
    max-width: 635px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-sm {
    max-width: 960px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-md {
    max-width: 1200px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-xl {
    max-width: 1400px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-xxl {
    max-width: 1600px;
  }
}

.container {
  --container-max-width: 100%;
  --container-padding-x: calc(var(--bs-gutter-x) * 0.5);
  padding-right: var(--container-padding-x);
  padding-left: var(--container-padding-x);
}
@media (max-width: 767.98px) {
  .container {
    --container-padding-x: 0.75rem;
  }
}

@media (min-width: 576px) {
  .container, .container-xsm, .container-sm {
    --container-max-width: 800px;
  }
}
@media (min-width: 768px) {
  .container, .container-xsm, .container-sm, .container-md {
    --container-max-width: 960px;
  }
}
@media (min-width: 992px) {
  .container, .container-xsm, .container-sm, .container-md, .container-lg {
    --container-max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .container, .container-xsm, .container-sm, .container-md, .container-lg, .container-xl {
    --container-max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container, .container-xsm, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    --container-max-width: 1320px;
  }
}
.text-max-width {
  max-width: 720px;
}
.text-max-width-xxs {
  max-width: 480px;
}
.text-max-width-xs {
  max-width: 550px;
}
.text-max-width-sm {
  max-width: 720px;
}
.text-max-width-md {
  max-width: 900px;
}
.text-max-width-lg {
  max-width: 1000px;
}
.text-max-width-xl {
  max-width: 1140px;
}
.text-max-width-xxl {
  max-width: 1320px;
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xxl {
    max-width: 1320px !important;
  }
}

[data-object-position=topleft],
.object-position--topleft {
  object-position: top left;
}

[data-mask-position=topleft],
.mask-position--topleft {
  mask-position: top left;
}

[data-object-position=topcenter],
.object-position--topcenter {
  object-position: top center;
}

[data-mask-position=topcenter],
.mask-position--topcenter {
  mask-position: top center;
}

[data-object-position=topright],
.object-position--topright {
  object-position: top right;
}

[data-mask-position=topright],
.mask-position--topright {
  mask-position: top right;
}

[data-object-position=middleleft],
.object-position--middleleft {
  object-position: center left;
}

[data-mask-position=middleleft],
.mask-position--middleleft {
  mask-position: center left;
}

[data-object-position=middlecenter],
.object-position--middlecenter {
  object-position: center center;
}

[data-mask-position=middlecenter],
.mask-position--middlecenter {
  mask-position: center center;
}

[data-object-position=middleright],
.object-position--middleright {
  object-position: center right;
}

[data-mask-position=middleright],
.mask-position--middleright {
  mask-position: center right;
}

[data-object-position=bottomleft],
.object-position--bottomleft {
  object-position: bottom left;
}

[data-mask-position=bottomleft],
.mask-position--bottomleft {
  mask-position: bottom left;
}

[data-object-position=bottomcenter],
.object-position--bottomcenter {
  object-position: bottom center;
}

[data-mask-position=bottomcenter],
.mask-position--bottomcenter {
  mask-position: bottom center;
}

[data-object-position=bottomright],
.object-position--bottomright {
  object-position: bottom right;
}

[data-mask-position=bottomright],
.mask-position--bottomright {
  mask-position: bottom right;
}

.inset-s-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-1 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-1 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-2 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-2 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-3 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-3 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-4 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-4 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-5 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-5 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-6 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-6 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-7 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-7 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-8 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-8 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-9 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-9 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-10 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-10 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-11 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-11 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-12 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-12 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-xs-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
}

.inset-e-xs-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
}

.inset-s-xs-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
}

.inset-e-xs-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
}

.inset-s-xs-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
}

.inset-e-xs-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
}

.inset-s-xs-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
}

.inset-e-xs-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
}

.inset-s-xs-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
}

.inset-e-xs-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
}

.inset-s-xs-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
}

.inset-e-xs-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
}

.inset-s-xs-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
}

.inset-e-xs-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
}

.inset-s-xs-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
}

.inset-e-xs-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
}

.inset-s-xs-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
}

.inset-e-xs-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
}

.inset-s-xs-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
}

.inset-e-xs-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
}

.inset-s-xs-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
}

.inset-e-xs-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
}

.inset-s-xs-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
}

.inset-e-xs-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
}

@media (min-width: 390px) {
  .inset-s-xsm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-xsm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-xsm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-xsm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-xsm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-xsm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-xsm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-xsm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-xsm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-xsm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-xsm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-xsm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-xsm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-sm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-sm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-sm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-sm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-sm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-sm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-sm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-sm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-sm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-sm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-sm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-sm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-md-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-md-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-md-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-md-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-md-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-md-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-md-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-md-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-md-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-md-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-md-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-md-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-lg-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-lg-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-lg-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-lg-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-lg-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-lg-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-lg-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-lg-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-lg-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-lg-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-lg-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-lg-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-xl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-xl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-xl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-xl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-xl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-xl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-xl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-xl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-xl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-xl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-xl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-xl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-xxl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-xxl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-xxl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-xxl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-xxl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-xxl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-xxl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-xxl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-xxl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-xxl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-xxl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-xxl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
.pull-s,
.pull-e {
  --space-left: -0.75rem;
}
@media (min-width: 992px) {
  .pull-s,
  .pull-e {
    --space-left: calc(((100vw - var(--container-max-width) + (0.75rem)) / 2) * -1);
  }
}
@media (min-width: 1200px) {
  .pull-s,
  .pull-e {
    --space-left: calc(((100vw - var(--container-max-width) + (0.75rem)) / 2) * -1);
  }
}
@media (min-width: 1400px) {
  .pull-s,
  .pull-e {
    --space-left: calc(((100vw - var(--container-max-width) + (0.75rem)) / 2) * -1);
  }
}

.pull-s {
  margin-left: var(--space-left);
}

.pull-e {
  margin-right: var(--space-left);
}

@media (max-width: 767.98px) {
  .row {
    --bs-gutter-x: 0.8rem;
  }
}

.custom-scrollbar::-webkit-scrollbar-track, .markdown-content table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.custom-scrollbar::-webkit-scrollbar, .markdown-content table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .markdown-content table::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
}

.button, button,
input[type=button] {
  overflow: visible;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  border: 0;
  outline: none;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none; /* for input */
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none; /* for button */
}
.button:focus, button:focus,
input[type=button]:focus, .button:hover, button:hover,
input[type=button]:hover {
  outline: none;
  text-decoration: none;
}

.button {
  --button-background: var(--bs-muted);
  --button-background-darker: hsl(var(--bs-muted-h), var(--bs-muted-s), calc(var(--bs-muted-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-muted-h), var(--bs-muted-s), var(--bs-muted-l), 0.2);
  --button-color: white;
  --button-size: 2.6875rem;
  --button-padding-x: 1.375rem;
  --button-border-width: 1px;
  --button-border-radius: 0.3125rem;
  display: inline-flex;
  position: relative;
  height: var(--button-size);
  align-items: center;
  padding-right: var(--button-padding-x);
  padding-left: var(--button-padding-x);
  padding-bottom: 0.1em;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  background-color: var(--button-background);
  color: var(--button-color);
  fill: currentColor;
  border-radius: var(--button-border-radius);
  border: var(--button-border-width) solid transparent;
  cursor: pointer;
  font-size: 0.9375rem;
  justify-content: center;
}
@media (min-width: 768px) {
  .button {
    --button-size: 3.225rem;
    --button-padding-x: 1.65rem;
  }
}
@media (hover: hover) {
  .button:hover {
    background-color: var(--button-background-darker);
    color: var(--button-color);
  }
}
.button:disabled, .button[disabled=true] {
  pointer-events: none;
  opacity: 0.4;
}

.button {
  --icon-inset-factor: -0.5;
}
.button span + fstr-icon,
.button span + .css-icon {
  margin-inline-start: 0.5rem;
}
.button fstr-icon + span,
.button .css-icon + span {
  margin-inline-start: 0.5rem;
}
.button fstr-icon .icon {
  fill: currentColor;
}
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) fstr-icon:not(span + fstr-icon),
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) .css-icon:not(span + .css-icon) {
  margin-inline-start: calc(22px * var(--icon-inset-factor));
}
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) span + fstr-icon,
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) span + .css-icon {
  margin-inline-end: calc(22px * var(--icon-inset-factor));
}
.button .icon-chip {
  --chip-spacing: 6px;
  --icon-inset-factor: -1;
  width: calc(var(--button-size) / 1.5);
  height: calc(var(--button-size) / 1.5);
  background: white;
  border-radius: 50%;
  fill: var(--button-background);
  color: var(--button-background);
  display: flex;
  align-items: center;
  justify-content: center;
}
.button .icon-chip svg {
  width: 18px;
  height: 18px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.button .icon-chip + span {
  margin-inline-start: 0.5rem !important;
  margin-inline-end: 0.4em !important;
}
.button span + .icon-chip {
  margin-inline-start: 0.9em;
  margin-inline-end: calc((var(--button-padding-x) + var(--button-border-width)) * -1 + var(--chip-spacing)) !important;
}
.button.is--inactive {
  opacity: 0.5;
}

.button-icon {
  width: 2.6875rem;
  height: 2.6875rem;
  border-radius: 12.5rem;
  display: inline-flex;
  justify-content: center;
  padding: 0;
}
.button-icon fstr-icon {
  margin-inline-start: 0;
}
.button-icon span + fstr-icon {
  margin-inline-end: 0;
}

.button.button-link {
  background: transparent;
  color: var(--button-background);
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.button.button-link fstr-icon {
  min-width: 1.5em;
  min-height: 1.5em;
}
.button.button-link fstr-icon .icon {
  fill: currentColor;
}
@media (hover: hover) {
  .button.button-link:hover {
    background: transparent;
    color: var(--button-background-darker);
  }
}

.button.button-outline {
  background: transparent;
  color: var(--button-background);
  border-color: var(--button-background);
}
.button.button-outline fstr-icon .icon {
  fill: currentColor;
}
@media (hover: hover) {
  .button.button-outline:hover {
    background: var(--button-background-alpha);
    color: var(--button-background-darker);
    border-color: var(--button-background-darker);
  }
}

.button--lg {
  --button-size: 3.875rem;
  --button-padding-x: 1.9375rem;
  font-size: calc(1.2875rem + 0.45vw);
}
@media (min-width: 1200px) {
  .button--lg {
    font-size: 1.625rem;
  }
}
.button--lg.button-icon {
  width: 3.875rem;
  height: 3.875rem;
  min-width: 3.875rem;
}

.button--md {
  --button-size: 3.125rem;
  --button-padding-x: 1.5625rem;
  font-size: 1.25rem;
}
.button--md.button-icon {
  width: 3.125rem;
  height: 3.125rem;
  min-width: 3.125rem;
}

.button--sm, .app .fstr-cookie-notice .button {
  --button-size: 2.5rem;
  --button-padding-x: 1.25rem;
  font-size: 1rem;
}
.button--sm.button-icon, .app .fstr-cookie-notice .button-icon.button {
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
}

.button--xs {
  --button-size: 2rem;
  --button-padding-x: 1rem;
  font-size: 0.875rem;
}
.button--xs.button-icon {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
}

.button.button--primary {
  --button-background: var(--bs-primary);
  --button-background-darker: hsl(var(--bs-primary-h), var(--bs-primary-s), calc(var(--bs-primary-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-primary-h), var(--bs-primary-s), var(--bs-primary-l), 0.2);
  --button-color: white;
}

.button.button--secondary {
  --button-background: var(--bs-secondary);
  --button-background-darker: hsl(var(--bs-secondary-h), var(--bs-secondary-s), calc(var(--bs-secondary-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-secondary-h), var(--bs-secondary-s), var(--bs-secondary-l), 0.2);
  --button-color: var(--bs-primary);
}

.button.button--tertiary {
  --button-background: var(--bs-tertiary);
  --button-background-darker: hsl(var(--bs-tertiary-h), var(--bs-tertiary-s), calc(var(--bs-tertiary-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-tertiary-h), var(--bs-tertiary-s), var(--bs-tertiary-l), 0.2);
  --button-color: var(--bs-primary);
}

.button.button--light {
  --button-background: var(--bs-light);
  --button-background-darker: hsl(var(--bs-light-h), var(--bs-light-s), calc(var(--bs-light-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-light-h), var(--bs-light-s), var(--bs-light-l), 0.2);
  --button-color: var(--bs-dark);
}

.pixel-rust, .icon-twitter, .icon-sad, .icon-plus, .icon-loader, .icon-linkedin, .icon-instagram, .icon-heart, .icon-happy, .icon-facebook, .icon-cross, .icon-chevron-up, .icon-chevron-right, .icon-chevron-left, .icon-chevron-down, .icon-check, .icon-calendar-add, .icon-calendar, .icon-arrow-right, .icon-arrow-left {
  background: url("/assets/dist/svg/scss/svg/sprite.css.svg") no-repeat;
  display: inline-block;
}

.icon-arrow-left {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 0;
  background-position: var(--relative-pos);
}

.icon-arrow-right {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 4.317789291882556%;
  background-position: var(--relative-pos);
}

.icon-calendar {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 8.635578583765112%;
  background-position: var(--relative-pos);
}

.icon-calendar-add {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 12.953367875647668%;
  background-position: var(--relative-pos);
}

.icon-check {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 17.271157167530223%;
  background-position: var(--relative-pos);
}

.icon-chevron-down {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 21.58894645941278%;
  background-position: var(--relative-pos);
}

.icon-chevron-left {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 25.906735751295336%;
  background-position: var(--relative-pos);
}

.icon-chevron-right {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 30.22452504317789%;
  background-position: var(--relative-pos);
}

.icon-chevron-up {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 34.542314335060446%;
  background-position: var(--relative-pos);
}

.icon-cross {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 38.86010362694301%;
  background-position: var(--relative-pos);
}

.icon-facebook {
  --icon-width: 200;
  --icon-height: 200;
  --relative-pos: 0 49.6031746031746%;
  background-position: var(--relative-pos);
}

.icon-happy {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 60.44905008635578%;
  background-position: var(--relative-pos);
}

.icon-heart {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 64.76683937823834%;
  background-position: var(--relative-pos);
}

.icon-instagram {
  --icon-width: 25;
  --icon-height: 25;
  --relative-pos: 0 67.6246830092984%;
  background-position: var(--relative-pos);
}

.icon-linkedin {
  --icon-width: 15;
  --icon-height: 15;
  --relative-pos: 0 69.1533948030176%;
  background-position: var(--relative-pos);
}

.icon-loader {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 72.53886010362694%;
  background-position: var(--relative-pos);
}

.icon-plus {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 76.85664939550949%;
  background-position: var(--relative-pos);
}

.icon-sad {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 81.17443868739205%;
  background-position: var(--relative-pos);
}

.icon-twitter {
  --icon-width: 200;
  --icon-height: 200;
  --relative-pos: 0 98.21428571428571%;
  background-position: var(--relative-pos);
}

.pixel-rust {
  --icon-width: 200;
  --icon-height: 17.33;
  --relative-pos: 0 99.9437291608926%;
  background-position: var(--relative-pos);
}

fstr-icon {
  position: relative;
  display: inline-flex;
  min-width: 1em;
  min-height: 1em;
}
fstr-icon .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: inherit;
}

.icon-lg {
  width: 32px;
  height: 32px;
}
.icon-lg svg {
  width: 2rem;
  height: 2rem;
}

.icon-md {
  width: 28px;
  height: 28px;
}
.icon-md svg {
  width: 1.75rem;
  height: 1.75rem;
}

.icon-sm {
  width: 22px;
  height: 22px;
}
.icon-sm svg {
  width: 1.375rem;
  height: 1.375rem;
}

.icon-xs {
  width: 16px;
  height: 16px;
}
.icon-xs svg {
  width: 1rem;
  height: 1rem;
}

.markdown-content blockquote {
  position: relative;
  padding: 0 0 1.25em;
  margin: 4ex 0 4ex 0;
  font-family: "Courier New", serif;
  text-align: center;
  display: flex;
  flex-flow: column;
}
.markdown-content blockquote::before {
  content: "“";
  font-size: 3em;
  font-family: "Courier New", serif;
  font-weight: bold;
  color: var(--bs-primary);
  height: 1.5ex;
  line-height: 1;
  display: inline-block;
}
.markdown-content blockquote * {
  font-size: 1.2em;
}
.markdown-content blockquote *:last-child {
  margin-bottom: 0;
}
.markdown-content .fr-img-space-wrap {
  margin-top: 1.25rem;
  margin-bottom: 1.5rem;
}
.markdown-content .fr-inner {
  color: var(--bs-gray-600);
  margin: 5px 0 0;
  display: inline-block;
  font-size: 14px;
}
.markdown-content img {
  max-width: 100%;
}
.markdown-content table {
  margin-top: 1.25rem;
  margin-bottom: 1em;
  font-size: 0.875rem;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  display: block;
}
@media (max-width: 767.98px) {
  .markdown-content table {
    width: calc(100% + 1.5rem);
    margin-right: 1.5rem;
  }
}
.markdown-content table th {
  background: var(--bs-primary);
  color: white;
  font-weight: bold;
  text-align: left;
  padding: 0.7rem 0.5rem;
}
.markdown-content table tr td:not(:last-child) {
  border-right: 1px solid var(--bs-gray-200);
}
.markdown-content table tr th:not(:last-child) {
  border-right: 1px solid var(--bs-gray-200);
}
.markdown-content table tr td {
  padding: 0.5rem;
  min-width: 180px;
}
.markdown-content table tr td.fr-thick {
  font-weight: bold;
}
.markdown-content table tr td.fr-highlighted {
  border: 2px solid var(--bs-secondary);
}
.markdown-content table tr:nth-child(even) td {
  background: var(--bs-gray-100);
}
.markdown-content em,
.markdown-content i {
  font-style: italic;
}
.markdown-content strong {
  font-weight: 800;
}
.markdown-content h1,
.markdown-content .h1 {
  font-size: 2.625rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h2,
.markdown-content .h2 {
  font-size: 2.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h3,
.markdown-content .h3 {
  font-size: 1.75rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h4,
.markdown-content .h4 {
  font-size: 1.375rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h5,
.markdown-content .h5,
.markdown-content .app .fstr-cookie-notice .fstr-cookie-notice__title,
.app .fstr-cookie-notice .markdown-content .fstr-cookie-notice__title {
  font-size: 1.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h6,
.markdown-content .h6 {
  font-size: 1.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content ul,
.markdown-content ol {
  list-style: outside;
  padding-inline-start: 1.5rem;
  display: inline-block;
  margin-bottom: 1.25rem;
  width: 100%;
}
.markdown-content ul li,
.markdown-content ol li {
  display: list-item;
  list-style-type: initial;
  font-weight: 400;
}
.markdown-content ol li {
  list-style-type: decimal;
}
.markdown-content hr {
  margin: 1.5em 0;
}
.markdown-content p {
  margin-bottom: 3ex;
}
.markdown-content pre {
  white-space: pre-line;
  font-family: monospace;
  background: var(--bs-gray-100);
  padding: 1em;
  border-radius: 7px;
  margin-bottom: 1.5em;
}
.markdown-content .button + .button {
  margin-inline-start: 0.5em;
}
.markdown-content a:not(.button) {
  color: var(--bs-secondary);
}
@media (hover: hover) {
  .markdown-content a:not(.button):hover {
    color: hsl(var(--bs-secondary-h), var(--bs-secondary-s), calc(var(--bs-secondary-l) * 0.8));
  }
}
.markdown-content .fr-video {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 2ex;
}
.markdown-content .fr-video iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.markdown-content iframe,
.markdown-content object,
.markdown-content embed {
  width: 100%;
  height: auto;
}

.simple-markdown p:last-child {
  margin-bottom: 0;
}

.embed-removed {
  background-color: var(--bs-background);
  text-align: center;
  padding: 2em 1em;
  margin: 2em 0;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embed-removed .fstr-cookie-btn.button {
  background: var(--bs-secondary);
}
.embed-removed .fstr-cookie-btn.button:hover {
  background: hsl(var(--bs-secondary-h), var(--bs-secondary-s), calc(var(--bs-secondary-l) * 0.8));
}
.embed-removed .embed-removed__text {
  font-weight: 600;
  color: var(--bs-background-text-color);
  margin-bottom: 1em;
}

.app .fstr-cookie-notice {
  --cookie-notice-bg: white;
  border-radius: 20px !important;
  max-width: 600px;
  border-width: 2px;
  border-color: var(--bs-primary);
  border-radius: 0.625rem;
  padding: 2rem;
}
.app .fstr-cookie-notice .button-group {
  margin-top: 10px;
}
.app .fstr-cookie-notice .button-group .button--reject {
  display: none;
}
.app .fstr-cookie-notice .fstr-cookie-notice__title {
  margin-bottom: 2ex;
  color: var(--bs-primary);
}
.app .fstr-cookie-notice .fstr-cookie-notice__toggle-row__content,
.app .fstr-cookie-notice .fstr-cookie-notice__content {
  font-size: 0.85em;
}
.app .fstr-cookie-notice .fstr-cookie-notice__toggle-row__content.open {
  padding: 1ex 0;
}
.app .fstr-cookie-notice .toggle-button {
  margin-left: 1ex;
  width: auto;
  height: auto;
  background-color: transparent;
  fill: #002dcc;
  color: #002dcc;
  padding: 0;
}
.app .fstr-cookie-notice .toggle-button span:first-of-type {
  display: none;
}
.app .fstr-cookie-notice .toggle-button .chevron {
  margin-left: 0;
}
.app .fstr-cookie-notice .button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6em;
}
.app .fstr-cookie-notice .button {
  margin-bottom: 0 !important;
  text-align: center;
  justify-content: center;
}
.app .fstr-cookie-notice .button--settings {
  border: 2px solid;
  color: var(--bs-primary);
  background-color: transparent;
}
.app .fstr-cookie-notice .button--primary {
  background-color: var(--bs-primary);
}
.app .fstr-cookie-notice .button--primary:hover {
  background-color: var(--bs-primary);
}
.app .fstr-cookie-notice .button--close {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 40px;
  height: 40px;
  font-size: 0px;
  background-color: transparent;
  padding: 0;
  color: var(--bs-primary);
}
.app .fstr-cookie-notice .button--close::after, .app .fstr-cookie-notice .button--close::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 18px;
  height: 3px;
  border-radius: 2px;
  background-color: currentColor;
}
.app .fstr-cookie-notice .button--close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.app .fstr-cookie-notice .button--close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.embed-removed {
  position: relative;
  padding-top: 56.25%;
  background-color: var(--bs-primary-100);
  margin-bottom: 3ex;
}
.embed-removed__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
  text-align: center;
}
@media (max-width: 575.98px) {
  .embed-removed__inner {
    height: auto;
  }
}
.embed-removed .fstr-cookie-btn.button {
  font-size: 0.9em;
  margin-top: 3ex;
  background-color: var(--bs-primary);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
.circle-loader {
  animation: rotate 2s linear infinite;
  width: 30%;
}
.circle-loader circle {
  stroke-width: 0.2em;
}
.circle-loader__path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke: var(--bs-primary);
}

.screws::before, .screws::after {
  width: 1em;
  height: 1em;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: block;
}

.screws {
  --screw-offset: 10px;
  pointer-events: none;
}
.screws::before, .screws::after {
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 2;
  content: "";
}

.screws.is--top::before {
  top: var(--screw-offset);
  left: var(--screw-offset);
}
.screws.is--top::after {
  top: var(--screw-offset);
  right: var(--screw-offset);
}

.screws.is--bottom::before {
  bottom: var(--screw-offset);
  left: var(--screw-offset);
}
.screws.is--bottom::after {
  bottom: var(--screw-offset);
  right: var(--screw-offset);
}

:root {
  --chip-size: 32px;
  --chip-color: var(--bs-blue);
  --chip-bg-color: var(--bs-yellow);
}

.chip {
  width: var(--chip-size);
  height: var(--chip-size);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--chip-bg-color);
  color: var(--chip-color);
  font-weight: 700;
  font-size: 1.25rem;
}
.chip .icon {
  fill: currentColor;
  stroke: currentColor;
}

.chip--md {
  --chip-size: 38px;
}

.chip--blue {
  --chip-color: var(--bs-light);
  --chip-bg-color: var(--bs-blue);
}

.app {
  position: relative;
}

.app__router {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

router-outlet {
  display: none;
}

.button-style {
  padding: 10px 10px 20px;
  min-height: 120px;
}
.button-style .text-muted {
  font-size: 12px;
  margin-bottom: 6px;
  display: inline-block;
}

.color-list {
  display: flex;
  flex-wrap: wrap;
}
.color-list__item {
  width: 10%;
  padding: 0 3px;
  text-align: center;
  margin-bottom: 2.5rem;
}

.styleguide-color {
  display: inline-block;
  width: 100%;
  height: 0;
  padding-bottom: calc(100% - 8px);
  border: 4px solid var(--bs-gray-100);
  border-radius: 12.5rem;
}
.styleguide-color--active {
  border-color: var(--bs-gray-900);
}

.grid-showcase > * {
  outline: 1px solid var(--bs-blue);
}
.grid-showcase__inner {
  min-height: 85vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  outline: 1px solid var(--bs-red);
}
.grid-showcase--overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.grid-showcase--overlay .row {
  align-items: flex-start;
}
.grid-showcase--overlay .row > *:nth-child(0) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(1) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(2) {
  --bg: rgba(255, 247, 53, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(3) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(4) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(5) {
  --bg: rgba(53, 255, 134, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(6) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(7) {
  --bg: rgba(53, 255, 134, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(8) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(9) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(10) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(11) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(12) {
  --bg: rgba(255, 247, 53, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(13) {
  --bg: rgba(53, 255, 134, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(14) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(15) {
  --bg: rgba(255, 247, 53, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(16) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(17) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(18) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(19) {
  --bg: rgba(244, 246, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(20) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(21) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(22) {
  --bg: rgba(244, 246, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(23) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(24) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(25) {
  --bg: rgba(23, 162, 184, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(26) {
  --bg: rgba(23, 162, 184, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(27) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(28) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(29) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(30) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(31) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(32) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(33) {
  --bg: rgba(255, 247, 53, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(34) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(35) {
  --bg: rgba(53, 255, 134, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(36) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(37) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(38) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(39) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(40) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(41) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(42) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(43) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(44) {
  --bg: rgba(23, 162, 184, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(45) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(46) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(47) {
  --bg: rgba(255, 247, 53, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(48) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(49) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(50) {
  --bg: rgba(244, 246, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(51) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(52) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(53) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(54) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(55) {
  --bg: rgba(244, 246, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(56) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(57) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(58) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(59) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(60) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(61) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(62) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(63) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(64) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(65) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(66) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(67) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(68) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(69) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(70) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(71) {
  --bg: rgba(255, 247, 53, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(72) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(73) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(74) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(75) {
  --bg: rgba(53, 255, 134, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(76) {
  --bg: rgba(244, 246, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(77) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(78) {
  --bg: rgba(244, 246, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(79) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(80) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(81) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(82) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(83) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(84) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(85) {
  --bg: rgba(53, 255, 134, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(86) {
  --bg: rgba(0, 45, 204, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(87) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(88) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(89) {
  --bg: rgba(244, 246, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(90) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(91) {
  --bg: rgba(53, 255, 134, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(92) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(93) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(94) {
  --bg: rgba(53, 255, 134, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(95) {
  --bg: rgba(23, 162, 184, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(96) {
  --bg: rgba(255, 247, 53, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(97) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(98) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(99) {
  --bg: rgba(53, 255, 134, 0.5);
}
.grid-showcase--overlay .row .demo-block {
  min-height: 100px;
  display: block;
  background-color: var(--bg, gray);
  box-shadow: rgba(0, 0, 255, 0.3882352941) inset 0px 0px 0px 10px;
}

.headings {
  display: flex;
}
.headings > * {
  width: 50%;
}
.headings .jumbo-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-list {
  display: flex;
  flex-wrap: wrap;
}
.icon-list__item {
  width: 33.3333333333%;
  display: flex;
  align-items: center;
}

.button-styles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.button-styles > li {
  width: calc(33.3333333333% - 20px);
  margin-bottom: 50px;
  border-bottom: 1px solid var(--bs-gray-500);
  padding-bottom: 50px;
  margin-left: -10px;
}
@media (max-width: 767.98px) {
  .button-styles > li {
    width: calc(50% - 20px);
  }
}
@media (max-width: 575.98px) {
  .button-styles > li {
    width: calc(100% - 20px);
  }
}

.component-guide code {
  font-family: monospace;
  display: inline-block;
  padding: 5px 5px;
  background: var(--bs-gray-300);
  font-size: 1rem;
}

.intro {
  min-height: 50vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.footer {
  text-align: center;
}

.footer__nav-list {
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.grid-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}
.grid-overlay .grid-showcase__inner {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: rgba(255, 0, 0, 0.1490196078);
  outline: 1px solid rgba(255, 0, 0, 0.2901960784);
}

.breakpoint-util-list {
  position: fixed;
  top: 0;
  right: 0;
}
.breakpoint-util-list__item {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: sans-serif;
  background: var(--bs-primary);
  color: white;
  padding: 10px 30px;
}

.header__pixel-rust {
  width: 100%;
  height: 200px;
  margin-top: -100px;
  background-image: url("/assets/gfx/pixel-rust-white.svg");
  background-position: bottom;
  background-repeat: repeat-x;
}

@keyframes loader {
  0% {
    transform: translateX(0%) scaleX(0);
  }
  50% {
    transform: translateX(33%) scaleX(33%);
  }
  100% {
    transform: translateX(100%) scaleX(0%);
  }
}
.loading {
  position: fixed;
  top: 0;
  height: 5px;
  background: #dddddd;
  left: 0;
  right: 0;
  z-index: 9999999;
  display: flex;
}
.loading__progression {
  background: var(--bs-primary);
}
.loading__progression.type--flux {
  width: 100vw;
  animation: loader 2s infinite ease;
  transform-origin: left;
}

body {
  --topbar-height: 90px;
}
@media (min-width: 768px) {
  body {
    --topbar-height: 140px;
  }
}

.topbar {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 99;
}

.pt-topbar {
  padding-top: var(--topbar-height);
}

.logo {
  width: 100%;
  max-height: 80px;
  position: relative;
}
@media (min-width: 768px) {
  .logo {
    max-height: 120px;
  }
}

.card {
  --card-border-radius: 24px;
  border-radius: var(--card-border-radius);
  padding: 30px;
  position: relative;
}
.card.has--rust::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/assets/gfx/pixel-rust.svg");
  opacity: 0.1;
  background-position: bottom;
  background-repeat: repeat-x;
  pointer-events: none;
  overflow: hidden;
}
.card.theme-primary {
  background-color: var(--bs-primary);
  color: white;
}
.card.theme-secondary {
  background-color: var(--bs-secondary);
}
.card.theme-tertiary {
  background-color: var(--bs-tertiary);
}
.card.theme-white {
  background-color: var(--bs-white);
}

.event-date {
  font-family: "Orbitron", serif;
  color: var(--bs-blue);
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-date--end__label {
  font-weight: 400;
  color: black;
  text-transform: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date__tot {
  font-weight: 600;
  color: var(--bs-blue);
  text-transform: uppercase;
}

.date-card {
  text-align: center;
}
.date-card .screws::before,
.date-card .screws::after {
  width: 10px;
  height: 10px;
}

.quiz {
  overflow: hidden;
  display: block;
}

.quiz-list {
  --card-height: 60vh;
  --card-distance: 7px;
  position: relative;
  height: calc(var(--card-height) + (3 * var(--card-distance)));
}
.quiz-list__item {
  position: absolute;
  display: flex;
  width: calc(100% - (3 * var(--card-distance)));
  min-height: var(--card-height);
  z-index: calc(var(--quiz-items-total) - var(--quiz-item-index));
  transition: transform 0.2s;
  transform: translate3d(calc(var(--quiz-item-distance) * var(--card-distance)), calc(var(--quiz-item-distance) * var(--card-distance)), 0);
}
.quiz-list__item[data-distance="1"] {
  background-color: hsl(var(--bs-primary-h), var(--bs-primary-s), calc(var(--bs-primary-l) * 0.8));
}
.quiz-list__item[data-distance="2"] {
  background-color: hsl(var(--bs-primary-h), var(--bs-primary-s), calc(var(--bs-primary-l) * 0.6));
}
.quiz-list__item[data-distance="3"] {
  background-color: hsl(var(--bs-primary-h), var(--bs-primary-s), calc(var(--bs-primary-l) * 0.4));
}
.quiz-list__item.is--done {
  transition: transform 0.5s, opacity 0.2s 0.2s;
  transform: translate3d(calc(-100% - 20px), 0, 0);
  opacity: 0;
  pointer-events: none;
}
.quiz-list__item-loader {
  min-height: 50vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.quiz__question {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card.card--quiz {
  text-align: left;
}

app-quiz-slider {
  height: 100%;
}

.quiz-slider {
  --label-size: 4.2em;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.quiz-slider__custom-label {
  --slider-label-background: var(--bs-light);
  --slider-label-color: var(--bs-primary);
  position: absolute;
  transition: left 0.2s;
  background-color: var(--slider-label-background);
  color: var(--slider-label-color);
  width: var(--label-size);
  height: var(--label-size);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-37%);
}
.quiz-slider__custom-label::after {
  --height: calc(var(--label-size) / 3);
  --width: calc(var(--label-size) / 4);
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: var(--width) solid transparent;
  border-right: var(--width) solid transparent;
  border-top: var(--height) solid var(--slider-label-background);
  position: absolute;
  bottom: calc(var(--height) * -0.5);
  transition: rotation 0.2s;
  transform: rotate(calc(var(--slider-speed) * 300deg));
  transform-origin: center top;
}
.quiz-slider__custom-label app-emoji-animation {
  display: block;
  margin-top: 6px;
  position: relative;
  z-index: 10;
}
.quiz-slider__slider.mat-mdc-slider {
  --mdc-slider-handle-color: var(--bs-secondary);
  --mdc-slider-focus-handle-color: var(--bs-secondary);
  --mdc-slider-hover-handle-color: var(--bs-secondary);
  --mdc-slider-active-track-color: var(--bs-light);
  --mdc-slider-inactive-track-color: var(--bs-light);
  --mdc-slider-with-tick-marks-active-container-color: var(--bs-tertiary);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--bs-light);
  --mat-mdc-slider-ripple-color: var(--bs-light);
  --mat-mdc-slider-hover-ripple-color: rgba(255, 247, 53, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 247, 53, 0.2);
  width: 100%;
  margin-top: calc(var(--label-size));
}

.activity-card {
  border-radius: 1.25rem;
  background-color: var(--bs-light-blue);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 1.5rem 1rem;
}
.activity-card__chip {
  position: absolute;
  left: 1rem;
  top: 1rem;
}
.activity-card__title {
  margin-left: calc(var(--chip-size) + 5px);
  margin-right: calc(var(--chip-size) + 5px);
  margin-bottom: 0px;
  text-align: center;
  font-weight: 700;
  color: var(--bs-blue);
  line-height: 120%;
  font-size: 1.25rem;
  font-family: "Titillium Web", sans-serif;
}
.activity-card__figure {
  width: 100%;
  position: relative;
}
.activity-card__figure .date-card {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(1.5rem, -50%);
}
.activity-card__figure .date-card .card {
  border-radius: 0.625rem;
  padding: 1.5rem 1rem;
}
.activity-card__figure.has--endDate .date-card {
  position: relative;
  top: 0;
  right: 0;
  height: auto;
}
.activity-card__figure.has--endDate .date-card .card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.activity-card__figure.has--endDate .date-card .date__end,
.activity-card__figure.has--endDate .date-card .date__start {
  width: 40%;
}
.activity-card__figure.has--endDate .date-card .date__time {
  width: 100%;
  flex-direction: row;
  position: relative;
}
.activity-card__figure.has--endDate .date-card .date__time .chip {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0, 50%);
}
.activity-card__figure .fstr-youtube__container,
.activity-card__figure img {
  width: 100%;
  min-height: 200px;
  border-radius: 0.625rem;
}
.activity-card__footer, .activity-card__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.activity-card__paragraph {
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.activity-card__paragraph * {
  font-size: 1rem;
}

.roadmap .header__pixel-rust {
  margin-top: -49px;
  background-size: contain;
}
.roadmap__layout {
  row-gap: 1.5rem;
}