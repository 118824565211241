%screw {
  @include size(1em);
  background: rgba(black, 0.2);
  border-radius: 50%;
  display: block;
}

.screws {
  --screw-offset: 10px;
  pointer-events: none;

  &::before,
  &::after {
    @include abs(auto);
    z-index: 2;
    content: '';
    @extend %screw;
  }
}

.screws.is--top {
  &::before {
    top: var(--screw-offset);
    left: var(--screw-offset);
  }
  &::after {
    top: var(--screw-offset);
    right: var(--screw-offset);
  }
}
.screws.is--bottom {
  &::before {
    bottom: var(--screw-offset);
    left: var(--screw-offset);
  }
  &::after {
    bottom: var(--screw-offset);
    right: var(--screw-offset);
  }
}
