$card-colors: 'primary', 'secondary', 'tertiary', 'white';

.card {
  --card-border-radius: 24px;
  border-radius: var(--card-border-radius);
  padding: 30px;
  position: relative;

  &.has--rust {
    &::after {
      content: '';
      @include abs(0);
      background-image: url('/assets/gfx/pixel-rust.svg');
      opacity: 0.1;
      background-position: bottom;
      background-repeat: repeat-x;
      pointer-events: none;
      overflow: hidden;
    }
  }

  @each $color in $card-colors {
    &.theme-#{$color} {
      background-color: var(--bs-#{$color});

      @if $color == 'primary' {
        color: white;
      }
    }
  }
}
