.event-date {
  font-family: $font-family-display;
  color: var(--bs-blue);
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-date--end {
  &__label {
    font-weight: 400;
    color: black;
    text-transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.date__tot {
  font-weight: 600;
  color: var(--bs-blue);
  text-transform: uppercase;
}

.date-card {
  text-align: center;

  .screws::before,
  .screws::after {
    @include size(10px);
  }
}
