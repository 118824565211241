.app {
  position: relative;
}

.app__router {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

router-outlet {
  display: none;
}
